import React from 'react'
import ImageWrap from 'components/ImageWrap'
import MarkdownWrap from 'components/MarkdownWrap'
import Inview from 'components/Inview'

export default function TextImageFullBlock({ content, imageWider }) {
  const { title, body, image } = content

  return (
    <section className='text-image-full-block'>
      <Inview className='text container fade-in up'>
        <div className='wrap'>
          {title && <h2 dangerouslySetInnerHTML={{ __html: title }} />}
          <div className='border' />
          {body && (
            <div className='body'>
              <MarkdownWrap body={body} />
            </div>
          )}
        </div>
      </Inview>
      <Inview className={`image fade-in up`}>
        <div className='inner'>
          <ImageWrap image={image} />
        </div>
      </Inview>
    </section>
  )
}
