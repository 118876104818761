import React from 'react'
import { graphql } from 'gatsby'
import BannerBlock from 'components/blocks/BannerBlock'
import BodyBlock from 'components/blocks/BodyBlock'
import TextImageFullBlock from 'components/blocks/TextImageFullBlock'
import SingleImageFullBlock from 'components/blocks/SingleImageFullBlock'
import ProductsBlock from 'components/blocks/ProductsBlock'
import VideoBlock from 'components/blocks/VideoBlock'
import { useLocalization } from 'gatsby-theme-i18n'
import { tr } from 'content/strings'

export default function CollectionPost({ data }) {
  const { locale } = useLocalization()
  const page = data.strapiCollection
  if (!page) return false
  // const parent = data.strapiCollectionsPage
  const { title, introBannerBlock, collectionPostBuilder } = page
  const customBanner = {
    // pretitle: parent.title,
    // pretitle: tr('collections', locale),
    title,
    image: introBannerBlock?.image,
    textColor: introBannerBlock?.textColor,
    alignH: 'center',
    alignV: 'top',
  }
  return (
    <div className='page-diamond-post'>
      <BannerBlock content={customBanner} mobileBgBeige={true} />
      {collectionPostBuilder && <Post content={collectionPostBuilder} />}
    </div>
  )
}

const Post = ({ content }) => {
  const {
    introBodyBlock,
    productsSelect,
    textImageBlock,
    productsSelect2,
    videoBlock,
    bodyBlock2,
    productsSelect3,
    singleImageBlock,
  } = content

  return (
    <div className='diamond-post'>
      {introBodyBlock && <BodyBlock content={introBodyBlock} />}
      {productsSelect && productsSelect.products.length > 0 && (
        <ProductsBlock products={productsSelect.products} />
      )}

      {textImageBlock && <TextImageFullBlock content={textImageBlock} />}
      {productsSelect2 && productsSelect2.products.length > 0 && (
        <ProductsBlock products={productsSelect2.products} />
      )}
      {videoBlock && <VideoBlock content={videoBlock} />}
      {bodyBlock2 && <BodyBlock content={bodyBlock2} />}
      {productsSelect3 && productsSelect3.products.length > 0 && (
        <ProductsBlock products={productsSelect3.products} />
      )}
      {singleImageBlock && <SingleImageFullBlock content={singleImageBlock} />}
    </div>
  )
}

export const pageQuery = graphql`
  query ($locale: String!, $slug: String!) {
    strapiCollectionsPage(locale: { eq: $locale }) {
      title
    }
    strapiCollection(slug: { eq: $slug }, locale: { eq: $locale }) {
      title
      seo {
        title
        description
      }
      introBannerBlock: bannerBlock {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        textColor
      }
      collectionPostBuilder {
        introBodyBlock {
          title
          body
        }
        productsSelect {
          products {
            title
            excerpt
            slug
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        textImageBlock {
          title
          body
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        productsSelect2 {
          products {
            title
            excerpt
            slug
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        bodyBlock2 {
          title
          body
        }
        productsSelect3 {
          products {
            title
            excerpt
            slug
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        singleImageBlock {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`

// videoBlock {
//   optimizedVideos {
//     localFile {
//       publicURL
//     }
//   }
// }
